import React, { Component } from 'react';
import styled from 'styled-components';

import Logo from 'Assets/Logo.svg';

import Button from 'Components/Button';

const Container = styled.div`
    margin: 25px 50px 50px 50px;
    display: flex;
    align-items: center;
`;

const Image = styled.img``;

class Navbar extends Component {
    render() {
        return (
            <Container>
                <Image src={Logo} />
                <Button
                    text={'DOCTOR SIGN IN'}
                    style={{ marginLeft: 'auto' }}
                />
                <div style={{ width: '15px' }} />
                <Button primary text={'POST'} />
            </Container>
        );
    }
}

export default Navbar;
