import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'Components/Button';
import Input from 'Components/Input';

const Container = styled.div`
    margin: 25px 50px 50px 50px;
    display: flex;
    align-items: center;
`;

class ItemBar extends Component {
    render() {
        return (
            <Container>
                <Input />
                <div style={{ width: '25px' }} />
                <Button text={'Option 1'} />
                <div style={{ width: '10px' }} />
                <Button text={'Option 2'} />
                <div style={{ width: '10px' }} />
                <Button text={'Option 3'} />
                <div style={{ width: '10px' }} />
                <Button text={'Option 4'} />
                <div style={{ width: '10px' }} />
                <Button text={'Option 5'} />
            </Container>
        );
    }
}

export default ItemBar;
