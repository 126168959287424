import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from 'Config/Colors';

const Container = styled.button`
    min-width: 125px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.4px;
    text-align: center;
    border: none;
    padding: 0 16px;
    border-radius: 5px;
    color: white;
    transition: all 0.2s cubic-bezier(0.2, 0.8, 0.4, 1);
    cursor: pointer;
    box-shadow: none;

    &:enabled {
        &:hover {
            filter: brightness(105%);
            box-shadow: 0 2px 4px 0 rgba(34, 35, 40, 0.3);
        }

        &:active {
            filter: brightness(100%);
            box-shadow: 0 2px 4px 0 rgba(34, 35, 40, 0.3);
        }
        outline: 0;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

class Button extends Component {
    render() {
        return (
            <Container
                onClick={this.props.onClick}
                style={{
                    ...this.props.style,
                    backgroundColor: this.props.primary
                        ? COLORS.red
                        : COLORS.gray,
                    height: this.props.height,
                    width: this.props.width,
                }}>
                {this.props.text}
            </Container>
        );
    }
}

export default Button;
