import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from 'Config/Colors';

const Container = styled.input`
    min-width: 250px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.4px;
    box-sizing: border-box;
    border: 2px solid gray;
    padding: 0 12px;
    border-radius: 5px;
    transition: all 0.2s cubic-bezier(0.2, 0.8, 0.4, 1);
    box-shadow: none;

    &:enabled {
        &:hover {
            filter: brightness(105%);
            box-shadow: 0 2px 4px 0 rgba(34, 35, 40, 0.3);
            border: 2px solid ${COLORS.gray};
        }

        &:active {
            filter: brightness(100%);
            box-shadow: 0 2px 4px 0 rgba(34, 35, 40, 0.3);
        }

        &:focus {
            border: 2px solid ${COLORS.gray};
        }
        outline: 0;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

class Button extends Component {
    render() {
        return (
            <Container
                onClick={this.props.onClick}
                style={{
                    ...this.props.style,
                    height: this.props.height,
                    width: this.props.width,
                }}>
                {this.props.text}
            </Container>
        );
    }
}

export default Button;
