import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 80%;
    position: relative;
    display: flex;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #c4c4c4;
    object-fit: cover;
`;

const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 35%,
        rgba(0, 0, 0, 0.6) 100%
    );
    display: flex;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.2, 0.8, 0.4, 1);
    &:hover {
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 35%,
            rgba(0, 0, 0, 0.4) 100%
        );
    }
`;

const Text = styled.div`
    position: absolute;
    bottom: 0;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    color: white;
    font-size: 22.5px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 5px;
`;

class Button extends Component {
    render() {
        return (
            <Container>
                <Image
                    onClick={this.props.onClick}
                    src={this.props.src}></Image>
                <Overlay />
                <Text>TEXT</Text>
            </Container>
        );
    }
}

export default Button;
