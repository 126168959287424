import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from 'Config/Colors';

const Container = styled.div`
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    background-color: ${COLORS.gray};
    border-radius: 50%;
    transition: all 0.2s cubic-bezier(0.2, 0.8, 0.4, 1);
    cursor: pointer;
    box-shadow: none;
    opacity: 0.5;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(34, 35, 40, 0.3);
        opacity: 0.90;
    }
`;

const ScrollButton = () => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop);

    return <Container onClick={scrollTop}>UP</Container>;
};

export default ScrollButton;
