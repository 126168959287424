import React, { Component } from 'react';
import styled from 'styled-components';

import Thumbnail from 'Components/Thumbnail';
import ScrollButton from 'Body/ScrollButton';

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const Grid = styled.div`
    display: grid;
    padding: 0 50px;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: 300px;
    grid-gap: 25px;
    background-color: #fff;
    color: #444;
`;

const Box = styled.div`
    display: flex;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    background-color: gray;
`;

class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
        };
    }

    componentDidMount() {
        fetch('https://picsum.photos/v2/list?page=1&limit=100')
            .then((response) => response.json())
            .then((res) =>
                this.setState({
                    images: res,
                })
            );
    }

    render() {
        this.items = Array.from(Array(100).keys());

        this.boxes = this.state.images.map((item, index) => {
            let url = "https://picsum.photos/id/" + item.id + "/600/500";
            return (
                <Box key={index}>
                    <Thumbnail src={url} />
                </Box>
            );
        });

        return (
            <Container>
                <Grid>{this.boxes}</Grid>
                <ScrollButton />
            </Container>
        );
    }
}

export default Body;
